@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }

  .always-scrollbar {
    scrollbar-width: 4px;
    scrollbar-track-color: transparent;
    scrollbar-face-color: var(--color-text-clickable-icon);
  }
  .always-scrollbar::-webkit-scrollbar {
    width: 12px;
  }
  .always-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .always-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--color-text-clickable-icon);
    border-right: 8px solid transparent;
    background-clip: padding-box;
  }

  .elevation-md {
    box-shadow: 0px 0px 1px 0px #0000003D, 0px 1px 2px 0px #0000003D;
  }
}
